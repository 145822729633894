import { styled } from 'styled-components';

export const Title = styled.h1`
  width: 100%;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Roboto Bold', Arial, sans-serif;
  font-size: 24px;
`;
