import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import footerBg from '../../assets/img/footer.png'

const Component = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  span {
    padding-bottom: 36px;
  }

  img {
    margin: 0 auto;
    width: 100%;
    min-width: 200px;
    max-width: 500px;
  }
`;

export const Footer = () => {
    const { pathname } = useLocation();

    return (
        <Component>
            <span>© 2023 - ООО «Интерактив»</span>
            { pathname === '/about' && <img src={footerBg} alt=""/> }
        </Component>
    );
};
