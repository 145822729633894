import { lazy } from 'react';

const About = lazy(() => import('../pages/About/About'));
const Owners = lazy(() => import('../pages/Owners/Owners'));
const Partners = lazy(() => import('../pages/Partners/Partners'));
const Contacts = lazy(() => import('../pages/Contacts/Contacts'));

export const routes = [
    {
        path: '/about',
        label: 'О компании',
        page: <About/>
    },
    {
        path: '/owners',
        label: 'Правообладатели',
        page: <Owners/>
    },
    {
        path: '/partners',
        label: 'Наши партнеры',
        page: <Partners/>
    },
    {
        path: '/contacts',
        label: 'Контакты',
        page: <Contacts/>
    },
]
