import React, {useCallback, useMemo} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { routes } from '../../routes';
import { ReactComponent as IconBurger } from '../../assets/icons/menu-burger.svg';
import { ReactComponent as IconClose } from '../../assets/icons/menu-close.svg';

const Component = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  button {
    appearance: none;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 14px;
  font-size: 12px;
  font-family: 'Roboto Bold', Arial, sans-serif;
  text-transform: uppercase;

  a {
    padding: 10px 5px;
    text-decoration: none;
    color: #707070;
  }
`;

export const Menu = ({ isOpen, toggle }) => {
    const { pathname } = useLocation();

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });

    const Icon = isOpen ? <IconClose/> : <IconBurger/>;

  const titleColor = useMemo(() => {
    switch (pathname) {
      case '/about':
        return '#EC5C3A';
      case '/owners':
        return '#C03275';
      case '/partners':
        return '#9A278C';
      case '/contacts':
        return '#383791';
      default:
        return '#707070';
    }
  }, [pathname]);

  const getColor = useCallback((route) => {
    if (pathname === route) {
      if (route === '/about') {
        return '#EC5C3A';
      }
      if (route === '/owners') {
        return '#C03275';
      }
      if (route === '/partners') {
        return '#9A278C';
      }
      if (route === '/contacts') {
        return '#383791';
      }
    }

    return '#707070';
  }, [pathname])

    return (
        <Component>
            {
                isMobile
                    ?   <button onClick={toggle}>{Icon}</button>
                    :   <Nav $selecterColor={titleColor}>
                            {
                                routes.map((route) =>
                                    <Link
                                        key={route.path}
                                        to={route.path}
                                        style={{ color: getColor(route.path) }}
                                    >
                                        {route.label}
                                    </Link>
                                )
                            }
                        </Nav>
            }
        </Component>
    )
};
