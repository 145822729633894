import React from 'react';
import styled from 'styled-components';
import { Menu } from '../Menu/Menu';
import { ReactComponent as Logo } from '../../logo.svg';

const Component = styled.header`
  width: 100%;
  z-index: 99;

  @media (max-width: 768px) {
    background: #fff;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  height: 108px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const Header = ({ menuIsOpen, toggleMenu }) => (
    <Component>
        <Content>
            <Logo/>
            <Menu isOpen={menuIsOpen} toggle={toggleMenu}/>
        </Content>
    </Component>
);