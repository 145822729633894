import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { routes } from '../routes';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { MobileNav } from '../components/MobileNav/MobileNav';
import { Title } from '../components/Title/Title';
import bgImage from '../assets/img/background.png';

const Component = styled.main`
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-attachment: scroll;
  background-size: 1280px;

  @media (max-width: 768px) {
    background-position: 50% 100px;
    background-size: 1600px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  padding: 48px 24px 24px 24px;
  flex-grow: 1;
`;

const PageTitle = styled(Title)`
  color: ${({ color }) => color};
`;

const App = () => {
    const [mobileMenuIsOpen, setMobileMenu] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });

    const toggleMenu = () => {
        setMobileMenu((state) => !state);
    }

    const title = useMemo(() => {
        switch (pathname) {
            case '/about':
                return { text: 'О компании', color: '#EC5C3A' };
            case '/owners':
                return { text: 'Правообладатели', color: '#C03275' };
            case '/partners':
                return { text: 'Наши партнеры', color: '#9A278C' };
            case '/contacts':
                return { text: 'Контакты', color: '#383791' };
            default:
                return '';
        }
    }, [pathname]);

    useEffect(() => {
        const isValidRoute = routes.find((r) => r.path === pathname);
        const isPDFUrl = pathname === '/docs/sout-2018.pdf' || pathname === '/docs/sout-2022.pdf';

        if (!isValidRoute && !isPDFUrl) {
            navigate('/about');
        }
    }, [pathname]);

    return (
        <Component>
            <Header menuIsOpen={mobileMenuIsOpen} toggleMenu={toggleMenu}/>
            {
                isMobile
                && <MobileNav isOpen={mobileMenuIsOpen} toggleMenu={toggleMenu}/>
            }
            {
                isMobile
                && <PageTitle color={title.color}>{ title.text }</PageTitle>
            }
            <Content>
                <Suspense fallback=''>
                    <Routes>
                        {
                            routes.map((route) =>
                                <Route key={route.path} path={route.path} element={route.page}/>
                            )
                        }
                    </Routes>
                </Suspense>
            </Content>
            <Footer/>
        </Component>
    );
};

export default App;
