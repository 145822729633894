import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../routes';

const Component = styled.nav`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${({ $isOpen }) => $isOpen ? '108px' : '-220px'};
  left: 0;
  width: 100%;
  font-size: 24px;
  background: #fff;
  border-radius: 0 0 24px 24px;
  height: 220px;
  justify-content: space-between;
  transition: .5s ease;
  padding: 24px;
  z-index: 2;

  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 24px;
    color: #707070;
    text-align: center;
    font-family: 'Roboto Bold', Arial, sans-serif;
  }

  a.colored {
    color: #EC5C3A;
  }
`;

export const MobileNav = ({ isOpen, toggleMenu }) => {
    const { pathname } = useLocation();

    return (
        <Component $isOpen={isOpen}>
            {
                routes.map((route) =>
                    <Link
                        key={route.path}
                        to={route.path}
                        className={pathname === route.path ? 'colored' : ''}
                        onClick={toggleMenu}
                    >
                        { route.label }
                    </Link>
                )
            }
        </Component>
    );
};
